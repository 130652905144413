import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        login: 'Login',
        email: 'Email',
        password: 'Password',
        loginButton: 'Login',
        logout: 'Logout',
        products: 'Products',
        home: 'Home',
        cancel: 'Cancel',
        confirm: 'Confirm',
        edit: 'Edit',
        save: 'Save',
        delete: 'Delete',
        productDeleteConfirmation: 'Are you sure you want to delete this product?',
        productDeleteConfirmationTitle: 'Delete product',
        barcode: 'Barcode',
        name: 'Name',
        sap: 'SAP',
        supplier: 'Supplier',
        weightCode: 'Weight code',
        addProduct: 'Add Product',
        addNewProduct: 'Add new product',
        search: 'Search',
        showSearch: 'Show search',
        hideSearch: 'Hide search',
        find: 'Find',
        add: 'Add',
        editProduct: 'Edit Product',
        next: 'Next',
        previous: 'Previous',
        shops: 'Shops',
        id: 'ID',
        city: 'City',
        addNewShop: 'Add new shop',
        importProduct: 'Import products',
        refresh: 'Refresh',
        shopDeleteConfirmation: 'Are you sure you want to delete this shop?',
        shopDeleteConfirmationTitle: 'Delete shop',
        users: 'Users',
        userName: 'User name',
        addNewUser: 'Add new user',
        primaryShop: 'Primary shop',
        addAdditionalShop: 'Add additional shop',
        additionalShops: 'Additional shops',
        reportTypes: 'Report types',
        addUser: 'Add user',
        editUser: 'Edit user',
        noOptions: 'No options',
        uploadFile: 'Upload file',
        productsImported: 'Products were successfully imported.',
        productsImportFailed: 'Products import failed.',
        returnablePackage: 'Returnable package',
        noContainer: 'No container',
        glassContainer: 'Glass container',
        plasticContainer: 'Plastic container',
        metalContainer: 'Metal container',
        barcodeIsRequired: 'Barcode is required',
        addShop: 'Add shop',
        editShop: 'Edit shop',
        suppliers: 'Suppliers',
        addNewSupplier: 'Add new supplier',
        addSupplier: 'Add supplier',
        editSupplier: 'Edit supplier',
        supplierDeleteConfirmationTitle: 'Delete supplier',
        supplierDeleteConfirmation: 'Are you sure you want to delete this supplier?',
        fieldIsRequired: 'This field is required',
        searchBy: 'Search by',
        byBarcode: 'Barcode',
        bySap: 'SAP',
        bySupplier: 'Supplier',
        reports: 'Reports',
        documentDate: 'Document date',
        shopId: 'Shop ID',
        documentId: 'Document ID',
        reportType: 'Report type',
        weight: 'Weight',
        quantity: 'Quantity',
        maxReportCountWarning: 'Maximum report count reached. Please refine your search criteria.',
        dateAfter: 'Date after',
        dateBefore: 'Date before',
        download: 'Download',
        export: 'Export',
      },
    },
    lt: {
      translation: {
        login: 'Prisijungti',
        email: 'El. paštas',
        password: 'Slaptažodis',
        loginButton: 'Prisijungti',
        logout: 'Atsijungti',
        products: 'Produktai',
        home: 'Pradžia',
        cancel: 'Atšaukti',
        confirm: 'Patvirtinti',
        edit: 'Redaguoti',
        save: 'Išsaugoti',
        delete: 'Ištrinti',
        productDeleteConfirmation: 'Ar tikrai norite pašalinti šį produktą?',
        productDeleteConfirmationTitle: 'Pašalinti produktą',
        barcode: 'Barkodas',
        name: 'Pavadinimas',
        sap: 'SAP',
        supplier: 'Tiekėjas',
        weightCode: 'Svorio kodas',
        addProduct: 'Pridėti produktą',
        addNewProduct: 'Pridėti naują produktą',
        search: 'Ieškoti',
        showSearch: 'Rodyti paiešką',
        hideSearch: 'Slėpti paiešką',
        find: 'Rasti',
        add: 'Pridėti',
        editProduct: 'Redaguoti produktą',
        next: 'Kitas',
        previous: 'Ankstesnis',
        shops: 'Parduotuvės',
        id: 'ID',
        city: 'Miestas',
        addNewShop: 'Pridėti naują parduotuvę',
        importProduct: 'Importuoti produktus',
        refresh: 'Atnaujinti',
        shopDeleteConfirmation: 'Ar tikrai norite pašalinti šią parduotuvę?',
        shopDeleteConfirmationTitle: 'Pašalinti parduotuvę',
        users: 'Naudotojai',
        userName: 'Naudotojo vardas',
        addNewUser: 'Pridėti naują naudotoją',
        primaryShop: 'Pagrindinė parduotuvė',
        addAdditionalShop: 'Pridėti papildomą parduotuvę',
        additionalShops: 'Papildomos parduotuvės',
        reportTypes: 'Ataskaitų tipai',
        addUser: 'Pridėti naudotoją',
        editUser: 'Redaguoti naudotoją',
        noOptions: 'Nėra pasirinkimų',
        uploadFile: 'Įkelti failą',
        productsImported: 'Produktai buvo sėkmingai importuoti.',
        productsImportFailed: 'Produktų importavimas nepavyko.',
        returnablePackage: 'Grąžinamoji pakuotė',
        noContainer: 'Be taros',
        glassContainer: 'Stiklinė tara',
        plasticContainer: 'Plastikinė tara',
        metalContainer: 'Metalinė tara',
        barcodeIsRequired: 'Barkodas yra privalomas',
        addShop: 'Pridėti parduotuvę',
        editShop: 'Redaguoti parduotuvę',
        suppliers: 'Tiekėjai',
        addNewSupplier: 'Pridėti naują tiekėją',
        addSupplier: 'Pridėti tiekėją',
        editSupplier: 'Redaguoti tiekėją',
        supplierDeleteConfirmationTitle: 'Pašalinti tiekėją',
        supplierDeleteConfirmation: 'Ar tikrai norite pašalinti šį tiekėją?',
        fieldIsRequired: 'Šis laukas yra privalomas',
        searchBy: 'Ieškoti pagal',
        byBarcode: 'Barkodą',
        bySap: 'SAP',
        bySupplier: 'Tiekėją',
        reports: 'Ataskaitos',
        documentDate: 'Dokumento data',
        shopId: 'Parduotuvės ID',
        documentId: 'Dokumento ID',
        reportType: 'Ataskaitos tipas',
        weight: 'Svoris',
        quantity: 'Kiekis',
        maxReportCountWarning:
          'Pasiektas maksimalus ataskaitų skaičius. Prašome patikslinti paieškos kriterijus.',
        dateAfter: 'Data nuo',
        dateBefore: 'Data iki',
        download: 'Atsisiųsti',
        export: 'Eksportuoti',
      },
    },
  },
  lng: 'lt',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
