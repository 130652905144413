import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Tooltip from '@mui/material/Tooltip';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

interface ExportButtonProps {
  language: string;
}

const ExportButton: React.FC<ExportButtonProps> = observer((exportButtonProps) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleExport = async () => {
    try {
      setLoading(true);
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken();
        const functionUrl = 'https://palomind.com/api/exportProducts';

        const response = await axios.post(
          functionUrl,
          {
            locale: exportButtonProps.language,
            projectId: '101',
          },
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          },
        );

        if (response.data?.downloadUrl) {
          // Open the returned download URL in a new tab
          window.open(response.data.downloadUrl, '_blank');
        } else {
          Sentry.captureMessage('Invalid response format:', response.data);
        }
      } else {
        Sentry.captureMessage('User not found');
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Tooltip title="Export Data">
      <Button
        color="primary"
        onClick={handleExport}
        startIcon={!loading && <CloudDownloadIcon />}
        disabled={loading}
      >
        {loading ? (
          <>
            <CircularProgress size={20} color="inherit" style={{ marginRight: 8 }} />
            {t('export')}
          </>
        ) : (
          <>{t('export')}</>
        )}
      </Button>
    </Tooltip>
  );
});

export default ExportButton;
